import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Remark from "../components/remark"
import Image from "../components/image"
import SEO from "../components/seo"

// TODO
//   Headings for blog, publications, resume.
// 
const IndexPage = () => (
  <Layout>
    <div className='blog'>
      <SEO title="Brandon Ballinger | Publications" />

      <a href='/'>Writing</a>
      {" | "}
      <a href='/publications'>Publications</a>
      {" | "}
      <a href='https://docsend.com/view/gqvmdizveh8x396e'>Resume</a>
      {" | "}
      <a href='https://gitlab.com/brandonballinger'>Gitlab</a>

      <h2>Publications</h2>
      <Remark>
        Academic publications and presentations on artificial intelligence, machine learning, and clinical medicine.
      </Remark>

      <ul>
        <li>
          <a href="https://ojs.aaai.org/index.php/AAAI/article/view/11891">
            DeepHeart: semi-supervised sequence learning for cardiovascular risk prediction. 
          </a>
          <Remark>
            &nbsp;AAAI Conference on Artificial Intelligence, 2018.
          </Remark>
        </li>
        <li>
          <a href="https://jamanetwork.com/journals/jamacardiology/article-abstract/2675364?redirect=true">
              Passive detection of atrial fibrillation using a commercially available smartwatch.
          </a>
          <Remark>&nbsp;Journal of the American Medical Association Cardiology, 2018</Remark>
        </li>

        <li>
          <Link to="https://sites.google.com/site/ml4hcws2016/posters">
              Semi-Supervised Sequence Learning for Continuous Digital Biomarkers. 
          </Link>
          <Remark>&nbsp;NeurIPS Machine Learning for Health Workshop. A more mature version was later published in AAAI-2018, above.</Remark>
        </li>


        <li>
          <Link to="https://static.googleusercontent.com/media/research.google.com/en//pubs/archive/36756.pdf">
             On-demand language model interpolation for mobile speech input. 
          </Link>
          <Remark>&nbsp;Interspeech 2010</Remark>
        </li>

        <li>
          <Link to="https://arxiv.org/abs/math/0611451">
            Experimental study of energy-minimizing point configurations on spheres. 
          </Link>
          <Remark>&nbsp;Experimental Mathematics, 2019 </Remark>
        </li>
      </ul>
    </div>
  </Layout>
)

export default IndexPage
